.App{
  height: 100%;
  width: inherit;

  .backdrop{
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: fixed;
  }

  .content-dark{
    height: 100%;
    width: inherit;
    // background-color: rgb(38, 40, 48);
    
  }

  .content-light{
    height: 100%;
    width: inherit;
    // background-color: rgb(38, 40, 48);
  }
}