.center{
    top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: transparent;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    will-change: transform, opacity;
    color: rgba(255, 255, 255, 0.856);
}